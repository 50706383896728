@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Licorice&family=Manrope:wght@200..800&display=swap");

/* -----------------root----------  */
:root {
  --ff-primary: "Lexend", sans-serif;
  --ff-secondary: "Manrope", sans-serif;
  --ff-home: "Licorice", cursive;

  --clr-dark: #060608;
  --clr-light-dark: #1c1c21;
  --clr-purple: #bb86fc;
  --clr-light: #797c86;
  --clr-white: #cccccc;
}

/* -------------------general style */
html {
  scroll-behavior: smooth;
}

body {
  background: var(--clr-dark);
  color: var(--clr-light);
  font-family: var(--ff-secondary);
}

button {
  padding: 14px 20px;
  background-color: var(--clr-light-dark);
  position: relative;
  border-radius: 8px;
  font-size: 14px;
  color: var(--clr-white);
  font-family: var(--ff-primary);
  font-weight: 500;
  box-shadow: -1px -1px 0px 0px rgba(47, 47, 55, 0.77);
  -webkit-box-shadow: -1px -1px 0px 0px rgba(47, 47, 55, 0.77);
  -moz-box-shadow: -1px -1px 0px 0px rgba(47, 47, 55, 0.77);
}

button:hover {
  background-color: rgba(47, 47, 55, 0.77);
}
section {
  border-bottom: 1px solid var(--clr-light-dark);
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}
.nav-inner-container {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  section {
    padding: 0px 16px;
  }
}
@media (min-width: 1500px) {
  section {
    padding: 0px 5px;
  }
}

/* custome scroll bar */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* other custome css */

.text-header {
  font-size: 1.15rem;
  font-size: clamp(1.15rem, 2vw, 3rem);
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--ff-primary);
  color: var(--clr-white);
}
.custom-btn-css {
  padding: 8px 24px !important;
}

@media (min-width: 640px) {
  .text-header {
    font-size: 1.875rem;
  }
  .custom-btn-css {
    padding: 12px 27px !important;
  }
}

@media (min-width: 800px) {
  .text-header {
    font-size: 2rem;
  }
  .custom-btn-css {
    padding: 14px 36px !important;
  }
}

@media (min-width: 900px) {
  .text-header {
    font-size: 2.2rem;
    line-height: 50px;
  }
}

@media (min-width: 1070px) {
  .text-header {
    font-size: 2.5rem;
  }
}

.line {
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: var(--clr-white);
  transition: width 0.4s ease-in-out;
}
.banner-image {
  border-radius: 7px;
  border: 2px solid rgba(47, 47, 55, 0.77);
}

.slider-design-process .testimonials-section {
  border-top: 1px solid var(--clr-light-dark);
}
.testimonials-section,
.why-section {
  border-top: 1px solid var(--clr-light-dark);
}

.circular-btn {
  display: flex;
  padding: 14px 36px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #797c86;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
}
.circular-btn:hover {
  background-color: var(--clr-purple);
  transition: transform 0.3s ease-in-out;
}
.circular-btn .arrow {
  transition: transform 0.3s ease-in-out;
}

.circular-btn:hover .arrow {
  transform: rotate(45deg);
}

.circular-btn .arrow-straight {
  transform: rotate(225deg);
}
.client-card,
.why-card {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.client-card::before,
.client-card::after,
.why-card::before,
.why-card::after {
  position: absolute;
  content: "";
  width: 320.391px;
  height: 303.309px;
  transition: all 0.5s;
  z-index: -1;
  border-radius: 22px;
  background: #111114;
}

.client-card::before {
  top: -49%;
  left: 56%;
  transform: rotate(-144.529deg);
}

.why-card::before {
  top: -35%;
  left: 64%;
  transform: rotate(-144.529deg);
}

.client-card::after {
  bottom: -61%;
  left: -42%;
  transform: rotate(39.64deg);
}
.why-card::after {
  bottom: -43%;
  left: -40%;
  transform: rotate(39.64deg);
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: rgb(174, 26, 26);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: rgb(174, 26, 26);
}

/* Custom Slick Dots */

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.ft-slick__dots--custom {
  height: 8px;
  width: 14px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  width: 14px;
  margin: 0 2px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
  width: 56px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 56px;
  top: -2px;
  overflow: hidden;
  background: var(--clr-purple);
}

option {
  background-color: var(--clr-dark);
  color: var(--clr-light);
  font-size: 18px;
  height: 20px;
}

/* Option hover styles (if supported by the browser) */
option:hover {
  background-color: red !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.side-box {
  display: block;
  position: absolute;
  content: none;
  z-index: 11;
  flex-shrink: 0;
  border-radius: 22px;
  background: linear-gradient(209deg, #bb86fc 0%, #060608 56.43%);
}
.box-left {
  display: none;
  transform: rotate(48deg);
  background: linear-gradient(225deg, #bb86fc 0%, #060608 51.43%);
}

.box-right {
  display: none;
  transform: rotate(-144.529deg);
  background: linear-gradient(209deg, #bb86fc 0%, #060608 71.09%);
}

@media (min-width: 800px) {
  .box-left {
    display: block;
    bottom: 5%;
    left: -33%;
    width: 262.914px;
    height: 272.023px;
    right: 0;
    background: linear-gradient(225deg, #bb86fc 0%, #060608 33.43%);
  }
  .box-right {
    display: block;
    top: 2%;

    right: -31%;
    width: 249.914px;
    height: 272.023px;
    background: linear-gradient(225deg, #bb86fc 0%, #060608 33.43%);
  }
}

@media (min-width: 1070px) {
  .box-left {
    bottom: 5%;
    left: -27%;
    width: 397.914px;
    height: 398.023px;
    right: 0;
    background: linear-gradient(225deg, #bb86fc 0%, #060608 51.43%);
  }
  .box-right {
    top: 2%;
    width: 334.914px;
    height: 350.023px;
    right: -26%;

    background: linear-gradient(230deg, #bb86fc 0%, #060608 46.43%);
  }
}

@media (min-width: 1280px) {
  .box-left {
    bottom: 5%;
    left: -23%;
    width: 397.914px;
    height: 398.023px;
    right: 0;
  }

  .box-right {
    top: 2%;
    width: 334.914px;
    height: 350.023px;
    right: -20%;

    background: linear-gradient(230deg, #bb86fc 0%, #060608 46.43%);
  }
}

@media (min-width: 1830px) {
  section {
    overflow: hidden;
  }
  .box-left {
    background: linear-gradient(225deg, #bb86fc 0%, #060608 33.43%);
  }
  .box-right {
    top: 2%;
    width: 334.914px;
    height: 350.023px;
    right: -18%;
    background: linear-gradient(230deg, #bb86fc 0%, #060608 46.43%);
  }
}

/* Apply the animation to the text items */
.scrolling-text-inner > div {
  animation: var(--direction) var(--marquee-speed) linear infinite;
}

/* Pause the animation when a user hovers over it */
/* .scrolling-text-container:hover .scrolling-text-inner > div {
  animation-play-state: paused;
} */

/* Setting the Animation using Keyframes */
@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.blog-card {
  box-shadow: 2px 2px 0px 0px var(--clr-purple);
  -webkit-box-shadow: 2px 2px 0px 0px var(--clr-purple);
  -moz-box-shadow: 2px 2px 0px 0px var(--clr-purple);
}
